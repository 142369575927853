'use strict';

import { LoginView } from './views/login.js';
import { PasswordView } from './views/password.js';
import { RecoverView } from './views/recover.js';
import { XhrHelper } from './xhr.js';
import { Tools } from './tools.js';

class Login {
    constructor() {
        let that = this;

        this._uniquid = 0;

        // Vues principales
        this.currentView = false;

        // Préchargement assets
        XhrHelper.loadFiles([
            // SVGs
            { name: 'svg/logo_full_dark', url: new URL('../media/svg/logo_full_dark.svg', import.meta.url) },
            { name: 'svg/picto_edit', url: new URL('../media/svg/picto_edit.svg', import.meta.url) }

        ], function(data) {
            that.files = data;

            // Vue courante
            that.goToPage(window.location.pathname, { noState: true });

            // Gestion navigation via navigateur
            window.addEventListener('popstate', function(event) {
                if(event.state || (window.location.pathname == '/')) {
                    that.goToPage(window.location.pathname, { noState: true });
                }
            });
        });
    }
    
    goToPage (href, params) {
        const   url = new URL(window.location.origin + href),
                path = url.pathname.split('/').filter(function (el) { return el != ''; });
        var view = this.currentView;

        // Destruction vue précédente
        if(view) view.destroy();
        
        // Routage nouvelle vue
        if(path.length) {
            switch(path[0]) {
                case 'recover': view = new RecoverView(this); break;
                case 'password': view = new PasswordView(this); break;
                // Login (par défaut)
                default: view = new LoginView(this); break;
            }
        } else {
            // Login (par défaut)
            view = new LoginView(this);
        }
        // Rendu vue courante
        view.render();

        // Historique navigateur
        if(!params || (!params.noState || !Tools.isDefined(params.noState))) {
            history.pushState({ path: path }, path[0], '/'+(path.length ? path.join('/') : ''));
        }
    }

	uniquid () {
		this._uniquid += 1;
		return (this._uniquid - 1);
	}
}

export { Login };

var login = new Login();