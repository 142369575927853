'use strict';

import { View } from '../view.js';
import { System } from '../models/system.js';

class PasswordView extends View {
    constructor(app) {
        super(app);
        this.name = 'Password';

        this.pwdPattern = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,20}$',
        this.pwdHint = 'De 8 à 20 caractères, comprenant au moins un chiffre (0-9), une minuscule (a-z), une majuscule (A-Z) et un caractère spécial (!@#$%^&*=+-_)';
    }

    get html () {
        var that = this,
            urlParams = new URLSearchParams(window.location.search),
            key = urlParams.get('key');

        return /*html*/`
            <div class="height-100vh">
                <div class="middle-holder">
                    <div class="middle">
                        <div class="center-holder">
                            <div class="svg-fit-height height-8em inline-block">
                                ${this.app.files['svg/logo_full_dark']}
                            </div>
                            <div class="top-margin-5">
                                <div class="width-login-col max-width-100 centered">
                                    <div class="center-holder color-darkblue">
                                        <div class="t-big medium">Création de votre <br class="hide-mobile">nouveau mot de passe</div>
                                    </div>
                                    <form class="form left-holder width-100 top-margin-1" id="form-system-password" method="post" enctype="multipart/form-data" id="password" action="/password" onsubmit="event.preventDefault(); return false;">
                                        <div class="message center-holder t-small border-1 border-color-grey padding-1 rounded-0-5em opacity-0 active-opacity-100 transition-opacity active-bot-margin-2 active">
                                            <div class="t-small">${this.pwdHint}</div>
                                        </div>
                                        <div class="fields">
                                            ${this.formField({ type: 'password', id: 'input-newpwd', name: 'newpwd', label: 'Nouveau mot de passe', class: 'width-100', autocomplete: "new-password", pattern: this.pwdPattern, title: this.pwdHint, required: true })}
                                            ${this.formField({ type: 'password', id: 'input-newpwd2', name: 'newpwd2', label: 'Nouveau mot de passe (confirmation)', autocomplete: "new-password", class: 'width-100 top-margin-1-5', pattern: this.pwdPattern, title: this.pwdHint, required: true })}
                                            <input type="hidden" id="input-newpwd-key" name="newpwd-key" value="${key}">
                                            <div class="message"></div>
                                            ${this.btn({ type: 'submit', id: 'form-submit', content: 'Valider', class: 'btn primary rehausse width-100 top-margin-2',
                                                onClick: function(btn, event) {
                                                    event.preventDefault();
                                                    that.sendForm(btn);
                                                    return false;
                                                }
                                            })}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
    }
    
    sendForm (btn) {
        var that = this,
            form = document.getElementById('form-system-password'),
            message = form.querySelector('.message'),
            fields = form.querySelector('.fields'),
            data = {};
        form.reportValidity();
        if(form.checkValidity()) {
            btn.disabled = true; btn.classList.add('pending');
            data = {
                pwd: this.getFieldValue('input-newpwd'),
                pwd2: this.getFieldValue('input-newpwd2'),
                key: document.getElementById('input-newpwd-key').value
            }
            System.password(data, function(result) {
                btn.disabled = false; btn.classList.remove('pending');
                message.innerHTML = /*html*/`
                    <div class="t-std bold">La modification de votre mot de passe <br class="hide-mobile">a bien été prise en compte.</div> 
                    <div class="top-margin-1">
                        <div class="t-small">
                            Utilisez votre nouveau mot de passe pour vous connecter via la ${that.btn({ a: true, href: '/', id: 'goto-login', content: 'page de connexion', class: 'btn inline-block color-inherit padding-0 underline' })}.
                        </div>
                    </div>`;
                message.classList.add('color-rehausse', 'border-color-rehausse');
                fields.remove();
            }, function(request, status) {
                btn.disabled = false; btn.classList.remove('pending');
                message.innerHTML = /*html*/`
                    <div class="t-std bold">Erreur lors de la création <br class="hide-mobile">du mot de passe.</div> 
                    <div class="top-margin-1">
                        <div class="t-small">${that.pwdHint}</div>
                    </div>`;
                message.classList.add('active', 'color-red', 'border-color-red');
            })
        }
    }
}

export { PasswordView };