'use strict';

class XhrHelper {

    static request (params = {}) {
        var httpRequest = new XMLHttpRequest();
        httpRequest.onreadystatechange = function() {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    if(params.success) params.success((params.json ? JSON.parse(httpRequest.responseText) : httpRequest.responseText));
                } else {
                    if(params.error) params.error(httpRequest, httpRequest.status);
                    console.log('Il y a eu un problème avec la requête.');
                }
            }
        }
        if(params.json) {
            switch(params.method) {
                case 'GET':
                    httpRequest.open(params.method, params.url+(params.data ? XhrHelper.formatUrlParams(params.data) : ''));
                    httpRequest.setRequestHeader('Content-Type', 'application/json');
                    httpRequest.send();
                    break;
                case 'POST':
                case 'PUT':
                case 'DELETE':
                    httpRequest.open(params.method, params.url);
                    httpRequest.setRequestHeader('Content-Type', 'application/json');
                    httpRequest.send(JSON.stringify(params.data));
                    break;
            }
        } else {
            httpRequest.open(params.method, params.url+(params.data ? XhrHelper.formatUrlParams(params.data) : ''));
            httpRequest.send();
        }
    }

    static submitFile = function (inputEl, url, onProgress, onSuccess, onError) {
        var data = new FormData(),
            request = new XMLHttpRequest(),
            percentComplete,
            parsedResponse;
        if(inputEl.files && inputEl.files[0]) {
            data.append('file', inputEl.files[0]);
            request.open('POST', url); 
            request.upload.addEventListener('progress', function(e) {
                percentComplete = (e.loaded / e.total)*100;
                if(onProgress) onProgress(percentComplete);
            });
            request.addEventListener('load', function(e) {
                inputEl.value = '';
                if(request.response) {
                    parsedResponse = JSON.parse(request.response);
                    if(parsedResponse.status == 'success') {
                        if(onSuccess) onSuccess(parsedResponse);
                    } else { if(onError) onError(parsedResponse);}
                } else { if(onError) onError(request);}
            });
            request.send(data);
        } else {
            if(onError) onError('No file specified.');
        }
    }

    static formatUrlParams(params = {}){
        return "?" + Object.keys(params).map(function(key){
            return key+"="+encodeURIComponent(params[key])
        }).join("&");
    }

    // Charge plusieurs fichiers
    static loadFiles (files, callback, index, data) {
        let that = this;
        const i = (typeof index === 'undefined') ? 0 : index;
        var safeData = data ? data : [];
        if(files.length) {
            XhrHelper.request({
                method: 'GET',
                url: files[i].url,
                success: function (response) {
                    safeData[files[i].name] = response;
                    if(i < (files.length - 1)) {
                        XhrHelper.loadFiles(files, callback, (i + 1), safeData);
                    } else {
                        if(callback) callback(safeData);
                    }
                }
            });
        }
    }
}

export { XhrHelper };