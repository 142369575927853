'use strict';

import { Tools } from './tools.js';
import { XhrHelper } from './xhr.js';
import mediumEditor from '../lib/medium-editor/medium-editor.js';

class View {

    constructor (app) {
        this.app = app;

        this.preData = {};
        this.preActions = [];
        this.postActions = [];

        this.target = document.getElementsByTagName('app')[0];

        // Message d'erreur par défaut
        this.defaultRequiredMessage = Tools.defaultRequiredMessage();
    }

    // RENDU (DOM)
    render (callback) {
        var that = this;
        this.executePreActions(function() {
            that.target.innerHTML = that.html;
            that.executePostActions(function() {
                if(callback) callback();
            });
        });
    }

    destroy () {
        if(this.richEditors && this.richEditors.length) {
            this.richEditors.forEach(function(editor) {
                editor.instance.destroy();
            })
            this.richEditors = false;
        }
    }

    // TITRE
    title (content, type, id) {
        return /*html*/`
            <div class="table-title right-pad-2 top-pad-0-5 bot-pad-0-5">
                <div class="middle-holder">
                    <div class="middle">
                        <div class="${type == 'slim' ? 't-mider medium' : (type == 'list' ? 't-big bold' : 't-big bold')}" ${id  ? ('id='+id) : ''}>${content}</div>
                    </div>
                </div>
            </div>`;
    }

    // TITRE AVEC PICTO
    formPartTitle (content, svg) {
        return /*html*/`
            <div>
                <div class="t-mider bold">${content}</div>
                <div class="abs height-1-75em left-margin--2">
                    <div class="middle-holder">
                        <div class="middle">
                            <div class="svg-fit-width width-1-1em fill-color-bluegrey">${svg}</div>
                        </div>
                    </div>
                </div>
            </div>`
    }
    partTitle (content, svg) {
        return /*html*/`
            <div>
                <div class="left-pad-2">
                    <div class="t-mider bold">${content}</div>
                </div>
                <div class="abs height-1-75em">
                    <div class="middle-holder">
                        <div class="middle">
                            <div class="svg-fit-width width-1-1em fill-color-darkblue">${svg}</div>
                        </div>
                    </div>
                </div>
            </div>`
    }

    // ONGLETS
    tabs (params) {
        var that = this,
            id = params.id ? params.id : 'tabs-'+this.app.uniquid(),
            html = /*html*/`
            <div>
                <div id="${id}" class="tabs flexbox gap-h-3 gap-h-1-mobile no-select scrollable-x-mobile hide-scrollbar left-pad-2-mobile right-pad-2-mobile">`;
        params.items.forEach(function(element) {
            html += /*html*/`
                    <div class="tab no-select no-decoration cursor-pointer spe-parent ${element.active ? 'active' : ''}" ${element.target ? 'data-target="'+element.target+'"' : ''} ${element.href ? 'data-href="'+element.href+'"' : ''}>
                        <div class="middle-holder">
                            <div class="middle">
                                <div class="center-holder bot-pad-1 top-pad-1 top-pad-0-5-mobile bot-pad-0-5-mobile nowrap-mobile">
                                    <div class="
                                        ${params.textClass ? params.textClass : 't-mid bolder'} line-height-std 
                                        color-bluegrey active-spe-parent-color-darkblue 
                                        color-darkblue-mobile active-spe-parent-color-rehausse-mobile bg-color-lightestrehausse-mobile active-spe-parent-bg-color-lightrehausse-mobile 
                                        top-pad-0-5-mobile bot-pad-0-5-mobile left-pad-0-75-mobile right-pad-0-75-mobile rounded-0-5em-mobile">
                                        ${element.content}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="abs bot left-50 width-4-5em left-margin--2-25 height-0-35em bg-color-lightgrey active-spe-parent-bg-color-rehausse hide-mobile"></div>
                    </div>`;
        });
        html += /*html*/`
                </div>
                <div class="abs gradient-white-alpha-h width-2em only-mobile height-100 no-events"></div>
                <div class="abs right gradient-white-alpha-h-inv width-2em only-mobile height-100 no-events"></div>
            </div>`;
        
        // À l'activation
        if(params.onActive || params.subView) this.postActions.push(function(next) {
            const target = that.target.querySelector('#'+id);
            target.querySelectorAll('.tab').forEach(function(tab) {
                tab.addEventListener('click', function(event) { 
                    var activatedTab = event.target.closest('.tab'),
                        oldActivatedTab = event.target.closest('.tabs').querySelector('.tab.active'),
                        href = activatedTab.getAttribute('data-href'),
                        url = new URL(window.location.origin + href),
                        path = url.pathname.split('/').filter(function (el) { return el != ''; });
                    
                    // Classe bouton
                    if(oldActivatedTab) oldActivatedTab.classList.remove('active');
                    activatedTab.classList.add('active');
                    // Action
                    if(params.onActive) params.onActive(activatedTab);
                    // Changement de sous-vue
                    if(params.subView) {
                        params.subView.destroy();
                        params.subView = params.subViewCreator(activatedTab.getAttribute('data-target'));
                        // Passage de la vue hôte
                        params.subView.hostView = that;
                        // Rendu de la sous-vue dans la destination
                        params.subView.target = params.subViewTarget();
                        params.subView.render(function() {
                            // Titre et actions de page
                            var hostActions = that.pageActions,
                                subViewActions = params.subView.pageActions,
                                actions = (hostActions ? hostActions : '') + (subViewActions ? subViewActions : ''),
                                title = params.subView.pageTitle;
                            if(title && params.titleTarget) params.titleTarget().innerHTML = that.pageTitle+' / '+params.subView.pageTitle;
                            if(params.actionsTarget) {
                                params.actionsTarget().innerHTML = actions;
                                params.subView.executePostActions();
                            }
                            if(actions !== '') that.executePostActions();
                            // Historique navigateur et menu si hors modale
                            if(!that.modalHost) {
                                history.pushState({ path: path }, path[0], '/'+(path.length ? path.join('/') : ''));
                                that.app.menuView.updateNavigation();
                            }
                        });
                    }
                });
            });
            if(next) next();
        });

        // Sous-vue initiale
        if(params.subView) {
            this.postActions.push(function(next) {
                params.subView.target = params.subViewTarget();
                params.subView.render(function() {
                    // Titre et actions de page
                    var hostActions = that.pageActions,
                        subViewActions = params.subView.pageActions,
                        actions = (hostActions ? hostActions : '') + (subViewActions ? subViewActions : ''),
                        subViewTitle = params.subView.pageTitle;
                    if(params.titleTarget) params.titleTarget().innerHTML = that.pageTitle+(subViewTitle ? (' / '+subViewTitle) : '');
                    if(params.actionsTarget) {
                        params.actionsTarget().innerHTML = actions;
                        params.subView.executePostActions();
                    }
                    if(next) next();
                });
            });
        }

        return html;
    }

    // LISTES
    table (params) {
        var that = this,
            html = /*html*/`
            ${(params.count || params.filters) ? /*html*/`
            <div class="table-top flexbox column-mobile gap-h-3 bot-pad-1 ${params.noTop ? 'hidden' : ''}">
            ${(typeof params.count != 'undefined') ? /*html*/`
                <div>
                    <div class="middle-holder">
                        <div class="middle">
                            <div class="top-pad-1 bot-pad-1">
                                <span class="table-count bold">${params.count.value}</span> <span class="table-count-text">${(params.count.value > 1) ? params.count.label.plural : params.count.label.singular}</span> au total
                            </div>
                        </div>
                    </div>
                </div>` : ''}
            ${params.filters ? /*html*/`
                <div class="filters hidden-mobile active-visible-mobile">${params.filters}</div>` : ''}
            ${params.pagination ? /*html*/`
                <div class="pagination left-margin-auto">${this.tablePagination(params.pagination)}</div>` : ''}
            </div>
            ` : ''}
            <table class="width-100 block-mobile ${params.noSelect ? 'noselect' : ''} ${(params.count && params.count.value) ? '' : 'hidden'}" id="${params.id}" ${params.count ? /*html*/`data-count-singular="${params.count.label.singular}" data-count-plural="${params.count.label.plural}"` : ''}>
                <thead class="${params.headClass ? params.headClass : ''} hide-mobile">
                    <tr>
                        ${params.noSelect ? '' : /*html*/`
                            <th class="height-100 width-3em center-holder">
                                <label class="abs block width-100 height-100 cursor-pointer">
                                    <div class="middle-holder">
                                        <div class="middle left-pad-0-5">
                                            <input type="checkbox" class="width-checkbox height-checkbox padding-0 margin-0 border-color-grey rounded-0-25em cursor-pointer">
                                        </div>
                                    </div>
                                </label>
                            </th>`}`
        params.head.forEach(function(element, index) {
            html += /*html*/`
                        <th class="uppercase padding-0-25 top-pad-0-75 bot-pad-0-75 ${!index ? 'left-pad-1' : ''} left-holder ${element.class ? element.class : '' }"><span class="${params.headTextClass ? params.headTextClass : 'uppercase color-black' }">${element.content}</span></th>`
        });
        html += /*html*/`
                    </tr>
                </thead>
                <tbody class="flexbox-mobile column gap-1-5">
                    ${params.body ? that.tableBody(params.id, params.body) : ''}
                </tbody>
            </table>
        ${params.count ? /*html*/`
            <div class="message empty ${params.count.value ? 'hidden' : ''} padding-2 center-holder">
                <div class="t-std">Aucun${((params.count.label.gender && params.count.label.gender) == 'f') ? 'e' : ''} ${params.count.label.singular}.</div>
            </div>` : ''}`

        this.postActions.push(function(next) {
            var target = that.target.querySelector('#'+params.id),
                checkbox = target.querySelector('th input[type="checkbox"]');
            if(checkbox) checkbox.addEventListener('change', function() {
                var headCheckbox = this;
                target.querySelectorAll('tr:nth-child(even) td:nth-child(1) input[type="checkbox"]').forEach(function(el) {
                    if(!el.disabled) {
                        el.checked = headCheckbox.checked;
                        el.dispatchEvent(new Event('change'));
                    }
                })
            })
            if(next) next();
        });

        return html;
    }
    tablePagination (params) {
        var pageAmount = Math.ceil(params.total / params.limit),
            i,
            newPage,
            html = '';
        if(pageAmount > 1) {
            html = /*html*/`${this.btn({
                content: /*html*/`<div class="width-2em height-2em rounded flexbox center fill-color-black hover-direct-parent-border-color-rehausse hover-direct-parent-fill-color-rehausse transition-full-color"><div class="left-margin--0-125 width-0-5em svg-fit-width">${this.app.files['svg/picto_arrow_prev']}</div></div>`,
                class: 'btn',
                onClick: function(el, event) {
                    event.preventDefault();
                    newPage = Math.max(1, (params.page - 1));
                    if(newPage !== params.page) params.onPage(newPage);
                    return false;
                }
            })}`;
            for(i = 0; i < pageAmount; i += 1) {
                html += /*html*/`${this.btn({
                    content: /*html*/`<div class="padding-0-75"><div class="t-std bold page-index">${(i + 1)}</div></div>`,
                    class: 'btn rounded-0-5em active-bg-color-rehausse-important hover-color-rehausse transition-full-color color-darkblue active-color-white-important'+((params.page == (i + 1)) ? ' active' : ''),
                    onClick: function(el, event) {
                        event.preventDefault();
                        newPage = parseInt(el.querySelector('.page-index').innerText);
                        if(newPage !== params.page) params.onPage(newPage);
                        return false;
                    }
                })}`
            }
            html += /*html*/`${this.btn({
                content: /*html*/`<div class="width-2em height-2em rounded flexbox center fill-color-black hover-direct-parent-border-color-rehausse hover-direct-parent-fill-color-rehausse transition-full-color"><div class="left-margin-0-125 width-0-5em svg-fit-width">${this.app.files['svg/picto_arrow_next']}</div></div>`,
                class: 'btn',
                onClick: function(el, event) {
                    event.preventDefault();
                    newPage = Math.min(pageAmount, (params.page + 1));
                    if(newPage !== params.page) params.onPage(newPage);
                    return false;
                }
            })}`
        }
        return html;
    }
    toggleTableFilters (id) {
        var out = false;
        var filters = this.target.querySelector('#'+id+' .filters');
        if(filters.classList.contains('active')) {
            filters.classList.remove('active');
        } else {
            filters.classList.add('active');
            out = true;
        }
        return out;
    }
    
    tableBody (id, rows) {
        var html = '',
            that = this;
        rows.forEach(function(row) {
            html += that.tableRow(row);
        });

        this.postActions.push(function(next) {
            var target = that.target.querySelector('#'+id),
                deleteBtn = that.target.querySelector('.table-action-delete');
            target.querySelectorAll('td input[type="checkbox"]').forEach(function (el) {
                el.addEventListener('change', function() {
                    if(this.checked) {
                        this.closest('tr').classList.add('selected');
                    } else {
                        this.closest('tr').classList.remove('selected');
                        target.querySelector('th input[type="checkbox"]').checked = false;
                    }
                    if(target.querySelector('td input[type="checkbox"]:checked')) {
                        if(deleteBtn) deleteBtn.classList.add('active');
                    } else {
                        if(deleteBtn) deleteBtn.classList.remove('active');
                    }
                })
            })
            target.querySelectorAll('tr[data-href] td:not(.noselect)'+(!target.classList.contains('noselect') ? ':not(:first-child)' : '')).forEach(function (el) {
                var href = el.closest('tr').getAttribute('data-href');
                el.addEventListener('click', function() {
                    that.app.goToPage(href);
                })
            })
            if(next) next();
        });

        return html;
    }
    tableRow (row) {
        var html = /*html*/`
            <tr><td colspan="${row.cells.length}" class="height-0-25em height-0-5em-mobile"></td></tr>
            <tr data-value="${row.value}" class="
                ${row.noSelect ? 'left-pad-1-mobile' : 'left-pad-3-mobile' } right-pad-1-mobile top-pad-1-mobile bot-pad-1-mobile 
                flexbox-mobile wrap-mobile gap-0-5-mobile rounded-0-5em-mobile overflow-hidden-mobile 
                ${row.highlight ? 'bg-color-highlight' : 'bg-color-white'} 
                ${row.disabled ? 'opacity-60 no-events' : ''} 
                selected-bg-color-lightergrey-mobile 
                active-border-top-2-mobile active-border-bot-2-mobile active-border-right-2-mobile border-color-rehausse
                ${row.active ? 'active' : ''}" 
                ${row.href ? 'data-href="'+row.href+'"' : ''}
            >
            ${row.noSelect ? '' : /*html*/`
                <td class="
                    block-mobile abs-mobile top-0-mobile left-0-mobile width-3em height-100 v-align-middle 
                    left-pad-0-5 left-pad-0-mobile 
                    active-direct-parent-border-bot-2 active-direct-parent-border-top-2 border-none-mobile 
                    border-color-rehausse center-holder transition-bg-color 
                    selected-parent-bg-color-lightergrey selected-parent-bg-color-alpha-mobile no-decoration
                    ${row.href ? 'cursor-pointer hover-direct-parent-bg-color-lightestgrey hover-direct-parent-bg-color-alpha-mobile' : ''}">
                    <label class="abs rel-mobile block width-100 height-100 cursor-pointer">
                        <div class="middle-holder">
                            <div class="middle left-pad-0-5">
                                <input type="checkbox" ${row.disabled ? 'disabled' : ''} class="width-checkbox height-checkbox padding-0 margin-0 border-color-grey rounded-0-25em cursor-pointer">
                            </div>
                        </div>
                    </label>
                    <div class="abs left-0 top-0 height-100 width-0-25em bg-color-bluegrey active-parent-bg-color-rehausse" style="${row.color ? ('background-color:' + row.color + ';') : ''}"></div>
                </td>`}`
        row.cells.forEach(function(cell, index) {
            html += /*html*/`
                <td class=" padding-0-25 padding-0-mobile ${!index ? 'left-pad-1 left-pad-0-mobile' : ''} height-3em height-auto-mobile transition-bg-color 
                            selected-parent-bg-color-lightergrey selected-parent-bg-color-alpha-mobile 
                            v-align-${cell.align ? cell.align : 'middle'} 
                            ${row.href ? 'hover-direct-parent-bg-color-lightestgrey hover-direct-parent-bg-color-alpha-mobile' : ''} 
                            ${cell.noSelect ? 'noselect' : (row.href ? ' cursor-pointer' : '')} 
                            active-direct-parent-border-top-2 active-direct-parent-border-bot-2 
                            ${(index == row.cells.length - 1) ? 'active-direct-parent-border-right-2' : ''} border-none-mobile 
                            ${(cell.breakMobile || cell.cornerMobile) ? 'break-mobile' : ''} ${cell.cornerMobile ? (cell.cornerMobile.includes('top') ? 'order-first' : 'order-last') : ''} 
                            border-color-rehausse">
            ${cell.cornerMobile ? /*html*/`
                    <div class="abs-mobile ${cell.cornerMobile.includes('right') ? 'right-0-mobile' : ''} ${cell.cornerMobile.includes('bot') ? 'bot-0-mobile' : ''}">` : ''}
            ${!cell.html ? /*html*/`
                        <span class="t-std color-darkblue">` : ''}
                            ${cell.content}
            ${!cell.html ? /*html*/`
                        </span>` : ''}
            ${cell.cornerMobile ? /*html*/`
                    </div> ` : ''}
                </td>`
        });
        html += /*html*/`
            </tr>`

        return html;
    }
    updateTable (id, rows, count) {
        var table = this.target.querySelector('#'+id),
            tableCountSingular = table.getAttribute('data-count-singular'),
            tableCountPlural = table.getAttribute('data-count-plural'),
            tableBody = this.target.querySelector('#'+id+' tbody'),
            deleteBtn = document.querySelector('.table-action-delete'),
            tableCount = this.target.querySelector('#'+id).parentElement.querySelector('.table-top .table-count'),
            tableCountText = this.target.querySelector('#'+id).parentElement.querySelector('.table-top .table-count-text'),
            emptyMessage = this.target.querySelector('#'+id).parentElement.querySelector('.message.empty');
        tableBody.innerHTML = this.tableBody(id, rows);
        if(deleteBtn) deleteBtn.classList.remove('active');
        if(tableCount) tableCount.innerText = count;
        if(tableCountText) tableCountText.innerText = ((count > 1) ? tableCountPlural : tableCountSingular);
        if(count) { 
            table.classList.remove('hidden');
            emptyMessage.classList.add('hidden');
        } else { 
            table.classList.add('hidden');
            emptyMessage.classList.remove('hidden');
        }
    }
    updateTablePagination (id, params) {
        this.target.querySelector('#'+id+'-container .pagination').innerHTML = this.tablePagination(params);
    }

    // ITEMS DYNAMIQUES
    items (params) {
        var that = this,
            html = /*html*/`
            <div class="items flexbox gap-1 gap-0-5-mobile gap no-select" id="${params.id}">`;
        params.items.forEach(function(element) {
            html += /*html*/`
                <div ${element.value ? 'data-value="'+element.value+'"' : ''} class="item cursor-pointer ${params.class} ${element.active ? 'active' : ''} ${element.selected ? 'selected' : ''}" ${element.target ? 'data-target="'+element.target+'"' : ''}>
                    ${element.content}
                    ${params.remove ? /*html*/`<span class="remove"><span class="left-pad-0-5 color-bluegrey hover-color-red transition-t-color">&#10006;</span></span>` : ''}
                </div>`
        });
        html += /*html*/`
            </div>`;
            
        if(params.onClick) this.postActions.push(function(next) {
            var target = that.target.querySelector('#'+params.id);
            target.querySelectorAll('.item').forEach(function(item) {
                item.addEventListener('click', function(event) { 
                    params.onClick(event.target.closest('.item'));
                });
            });
            if(next) next();
        });
        if(params.onHover) this.postActions.push(function(next) {
            var target = that.target.querySelector('#'+params.id);
            target.querySelectorAll('.item').forEach(function(item) {
                item.addEventListener('mouseenter', function(event) { 
                    params.onHover(event.target.closest('.item'));
                });
            });
            if(next) next();
        });
        if(params.remove) this.postActions.push(function(next) {
            var target = that.target.querySelector('#'+params.id);
            target.querySelectorAll('.item .remove').forEach(function(el) {
                el.addEventListener('click', function(event) { 
                    event.preventDefault();
                    event.stopPropagation();
                    var item = event.target.closest('.item');
                    params.remove(item.getAttribute('data-value'), function() {
                        item.remove();
                    });
                    return false;
                });
            });
            if(next) next();
        });

        return html;
    }

    // BOUTONS
    btn (params) {
        var that = this,
            id = (params.id ? params.id : 'btn-'+this.app.uniquid()),
            html = /*html*/`
                <${params.a ? 'a' : 'button'} ${params.type ? 'type="'+params.type+'"' : ''} class="${params.class ? params.class : ''} ${params.icon ? 'icon' : ''}" ${params.href ? 'href="'+params.href+'"' : ''} id="${id}" tabindex="-1">
                    <div class="content">${params.content}</div>${params.icon ? /*html*/`<div class="icon-holder">${params.icon}</div>` : ''}
                </${params.a ? 'a' : 'button'}>`;
            
        if(params.onClick) this.postActions.push(function(next) {
            var target = that.target.querySelector('#'+id);
            target.addEventListener('click', function(event) {
                params.onClick(this, event);
            });
            if(next) next();
        });

        if(params.href) this.postActions.push(function(next) {
            var target = that.target.querySelector('#'+id);
            target.addEventListener('click', function(event) {
                const href = this.getAttribute('href');
                that.app.goToPage(href);
                event.preventDefault();
                return false;
            });
            if(next) next();
        });

        return html;
    }

    // FORMULAIRES
    // ---------------------
    // CHAMPS CLASSIQUES
    // -----------
    formField (params) {
        var that = this,
            i,
            value,
            amount = params.multiple ? params.value ? Math.max(1, params.value.length) : 1 : 1,
            html = /*html*/`
                <div id="field-${params.id}" class="field ${((params.type == 'text') || (params.type == 'number') || (params.type == 'password') || (params.type == 'email') || (params.type == 'phone') || (params.type == 'date') || (params.type == 'time') || (params.type == 'datetime-local')) ? 'text' : params.type} ${params.class ? params.class : ''} ${params.multiple ? 'multiple' : ''} ${params.readonly ? 'readonly' : ''}">`
        switch(params.type) {
            case 'text':
            case 'password':
            case 'email':
            case 'phone':
            case 'number':
            case 'date':
            case 'time':
            case 'datetime-local':
                if(params.label) html += /*html*/`
                        <label for="${params.id}">${params.label}${params.required ? ' *' : ''}</label>`
                html += /*html*/`
                        <div class="items-container">`
                for(i = 0; i < amount; i += 1) {
                    html += /*html*/`
                            <div class="item">
                                <input type="${params.type}" id="${params.id+((i > 0) ? ('-'+i) : '')}" name="${params.name+((i > 0) ? ('-'+i) : '')}" 
                                    ${(Tools.isDefined(params.value) && params.value !== false) ? 'value="'+
                                        (params.multiple ? 
                                            ((params.type == 'date') ? this.utcDateToLocal(params.value[i]) : ((params.type == 'datetime-local') ? this.utcDateTimeToLocal(params.value[i]) : params.value[i])) : 
                                            ((params.type == 'date') ? this.utcDateToLocal(params.value) : ((params.type == 'datetime-local') ? this.utcDateTimeToLocal(params.value) : params.value))
                                        )
                                    +'"' : ''} 
                                    ${params.pattern ? 'pattern="'+params.pattern+'"' : ''} 
                                    ${params.title ? 'title="'+params.title+'"' : ''} 
                                    class="${params.inputClass ? params.inputClass : ''}" 
                                    ${params.placeholder ? 'placeholder="'+params.placeholder+'"' : ''} 
                                    ${params.autocomplete ? 'autocomplete="'+params.autocomplete+'"' : ''} 
                                    ${params.required ? 'required' : ''} 
                                    ${params.readonly ? 'readonly' : ''} 
                                    ${Tools.isDefined(params.min) ? 'min="'+params.min+'"' : ''} 
                                    ${Tools.isDefined(params.max) ? 'max="'+params.max+'"' : ''}>
                                ${params.multiple ? /*html*/`<div class="abs right-100 remove top-pad-1 bot-pad-1 width-2em center-holder color-bluegrey hover-color-red cursor-pointer no-select ${!i ? 'opacity-0 no-events' : ''}">&#10006;</div>` : ``}
                                ${(['number'].indexOf(params.type) == -1) ? this.btn({
                                    class: 'btn abs right height-100 padding-0 flexbox center right-pad-0-75 left-pad-0-75',
                                    content: !params.readonly ? /*html*/`<div class="height-1-25em svg-fit-height fill-color-bluegrey hover-direct-parent-fill-color-rehausse transition-fill-color">${(['date', 'datetime-local'].indexOf(params.type) > -1) ? this.app.files['svg/picto_planning'] : this.app.files['svg/picto_edit']}` : '',
                                    onClick: function(el, event) {
                                        event.preventDefault();
                                        var mainInput = el.closest('.field').querySelector('input');
                                        if(['date', 'datetime-local'].indexOf(mainInput.getAttribute('type')) > -1) {
                                            mainInput.showPicker();
                                        } else {
                                            mainInput.focus();
                                        }
                                        return false;
                                    }
                                }) : ''}
                            </div>`
                }
                html += /*html*/`
                        </div>`
                break;
            case 'textarea':
                if(params.label) html += /*html*/`
                        <label for="${params.id}">${params.label}${params.required ? ' *' : ''}</label>`
                html += /*html*/`
                        <div class="items-container">`
                for(i = 0; i < amount; i += 1) {
                    html += /*html*/`
                            <div class="item">
                                <textarea rows="${params.rows}" type="${params.type}" id="${params.id+((i > 0) ? ('-'+i) : '')}" name="${params.name+((i > 0) ? ('-'+i) : '')}" ${params.pattern ? 'pattern="'+params.pattern+'"' : ''} ${params.title ? 'title="'+params.title+'"' : ''} class="${params.inputClass ? params.inputClass : ''}" ${params.placeholder ? 'placeholder="'+params.placeholder+'"' : ''} ${params.autocomplete ? 'autocomplete="'+params.autocomplete+'"' : ''} ${params.readonly ? 'readonly' : ''} ${params.required ? 'required' : ''}>${params.value ? (params.multiple ? (params.value[i] ? params.value[i] : '') : params.value) : ''}</textarea>
                                ${(params.multiple && !params.readonly) ? /*html*/`<div class="abs right-100 remove top-pad-1 bot-pad-1 width-2em center-holder color-bluegrey hover-color-red cursor-pointer no-select ${!i ? 'opacity-0 no-events' : ''}">&#10006;</div>` : ``}
                            </div>`;
                }
                html += /*html*/`
                        </div>`
                break;
            case 'color':
                if(params.label) html += /*html*/`
                        <label for="${params.id}">${params.label}${params.required ? ' *' : ''}</label>`
                html += /*html*/`
                        <div class="items-container">`
                for(i = 0; i < amount; i += 1) {
                    html += /*html*/`
                            <div class="item">
                                <div class="input-container">
                                    <div class="color-container">
                                        <input type="${params.type}" id="${params.id+((i > 0) ? ('-'+i) : '')}" name="${params.name+((i > 0) ? ('-'+i) : '')}" ${params.value ? 'value="'+(params.multiple ? params.value[i] : params.value)+'"' : ''} ${params.pattern ? 'pattern="'+params.pattern+'"' : ''} ${params.title ? 'title="'+params.title+'"' : ''} class="${params.inputClass ? params.inputClass : ''}" ${params.placeholder ? 'placeholder="'+params.placeholder+'"' : ''} ${params.autocomplete ? 'autocomplete="'+params.autocomplete+'"' : ''} ${params.required ? 'required' : ''} ${params.readonly ? 'readonly' : ''}>
                                    </div>
                                    <input class="hexa-value" type="text" ${params.value ? 'value="'+params.value+'"' : ''}>
                                </div>
                                ${params.multiple ? /*html*/`<div class="abs right-100 remove top-pad-1 bot-pad-1 width-2em center-holder color-bluegrey hover-color-red cursor-pointer no-select ${!i ? 'opacity-0 no-events' : ''}">&#10006;</div>` : ``}
                            </div>`
                }
                html += /*html*/`
                        </div>`
                break;
            case 'file':
                if(params.label) html += /*html*/`
                        <label for="${params.id}-selector">${params.label}${params.required ? ' *' : ''}</label>`
                html += /*html*/`
                        <div class="items-container">`
                for(i = 0; i < amount; i += 1) {
                    value = ((params.value && params.multiple) ? params.value[i] : params.value);
                    html += /*html*/`
                            <div class="item ${value ? '' : ('empty '+(params.multiple ? 'hidden' : ''))}">
                                <div class="input-container">
                                    ${this.btn({
                                        id: 'btn-image-zoom-field-'+params.id+'-'+i,
                                        content: /*html*/`<img src="${value}"/>`,
                                        class: 'image-container padding-0',
                                        onClick: function(el, event) {
                                            event.preventDefault();
                                            that.app.openModal('image', {
                                                id: 'image-zoom',
                                                src: el.querySelector('img').src
                                            });
                                            return false;
                                        }
                                    })}
                                    <div class="left-part">
                                        <label data-original="${params.inputLabel}" data-original-replace="${params.replaceLabel}" for="${params.id}-selector${((i > 0) ? ('-'+i) : '')}" class="bg-color-bluegrey hover-bg-color-mediumgrey transition-bg-color color-white rounded-0-5em">${params.value ? (params.multiple ? (params.value[i] ? params.replaceLabel : params.inputLabel) : params.replaceLabel) : params.inputLabel}</label>
                                    </div>
                                    <div class="text-container"></div>
                                </div>
                                <input type="${params.type}" id="${params.id}-selector${((i > 0) ? ('-'+i) : '')}" ${params.readonly ? 'readonly' : ''}>
                                <input type="hidden" id="${params.id+((i > 0) ? ('-'+i) : '')}" name="${params.name+((i > 0) ? ('-'+i) : '')}" ${params.value ? 'value="'+(params.multiple ? params.value[i] : params.value)+'"' : ''}>
                                ${params.multiple ? /*html*/`<div class="abs right-100 remove top-pad-1 bot-pad-1 width-2em center-holder color-bluegrey hover-color-red cursor-pointer no-select ${!i ? 'opacity-0 no-events' : ''}">&#10006;</div>` : ``}
                            </div>`
                }
                html += /*html*/`
                        </div>`
                break;
            case 'attachment':
                if(params.label) html += /*html*/`
                        <label for="${params.id}-selector">${params.label}${params.required ? ' *' : ''}</label>`
                html += /*html*/`
                        <div class="items-container">`
                for(i = 0; i < amount; i += 1) {
                    value = ((params.value && params.multiple) ? params.value[i] : params.value);
                    html += /*html*/`
                            <div class="item ${value ? '' : ('empty '+(params.multiple ? 'hidden' : ''))}">
                                <div class="input-container">
                                    ${this.btn({
                                        id: 'btn-image-zoom-field-'+params.id+'-'+i,
                                        content: /*html*/`<img src="${value}"/>`,
                                        class: 'image-container padding-0',
                                        onClick: function(el, event) {
                                            event.preventDefault();
                                            that.app.openModal('image', {
                                                id: 'image-zoom',
                                                src: el.querySelector('img').src
                                            });
                                            return false;
                                        }
                                    })}
                                    <div class="left-part">
                                        <label data-original="${params.inputLabel}" data-original-replace="${params.replaceLabel}" for="${params.id}-selector${((i > 0) ? ('-'+i) : '')}" class="bg-color-bluegrey hover-bg-color-mediumgrey transition-bg-color color-white rounded-0-5em">${params.value ? (params.multiple ? (params.value[i] ? params.replaceLabel : params.inputLabel) : params.replaceLabel) : params.inputLabel}</label>
                                    </div>
                                    <div class="text-container"></div>
                                </div>
                                <input type="${params.type}" id="${params.id}-selector${((i > 0) ? ('-'+i) : '')}" ${params.readonly ? 'readonly' : ''}>
                                <input type="hidden" id="${params.id+((i > 0) ? ('-'+i) : '')}" name="${params.name+((i > 0) ? ('-'+i) : '')}" ${params.value ? 'value="'+(params.multiple ? params.value[i] : params.value)+'"' : ''}>
                                ${params.multiple ? /*html*/`<div class="abs right-100 remove top-pad-1 bot-pad-1 width-2em center-holder color-bluegrey hover-color-red cursor-pointer no-select ${!i ? 'opacity-0 no-events' : ''}">&#10006;</div>` : ``}
                            </div>`
                }
                html += /*html*/`
                        </div>`
                break;
            case 'select':
                if(params.label) html += /*html*/`
                        <label for="${params.id}">${params.label}${params.required ? ' *' : ''}</label>`
                html += /*html*/`
                        <div class="items-container">`
                html += /*html*/`
                            <div class="item">
                                <select id="${params.id}" class="${params.inputClass ? params.inputClass : ''}" name="${params.name}" ${params.required ? 'required' : ''} ${params.readonly ? 'readonly' : ''}>`
                if(params.options && params.options.length) params.options.forEach(function(option) { 
                    html += /*html*/`
                                    <option value="${option.value}" ${params.value ? ((option.value == params.value) ? 'selected' : '') : ''}>${option.label}</option>`
                })
                        html += /*html*/`
                                </select>
                            </div>
                        </div>`
                break;
            case 'radio':
                if(params.label) html += /*html*/`
                        <label>${params.label}${params.required ? ' *' : ''}</label>`
                html += /*html*/`
                        <div class="items-container">`
                if(params.options && params.options.length) {
                    html += /*html*/`
                            <div class="item">
                                <div class="options">`
                    params.options.forEach(function(option, index) { 
                        html += /*html*/`
                                    <div class="option">
                                        <input type="radio" id="${params.id}-option-${index}" class="${params.inputClass ? params.inputClass : ''}" name="${params.name}" value="${option.value}" ${params.value ? ((option.value == params.value) ? 'checked' : '') : (option.default ? 'checked' : '')} ${params.readonly ? 'readonly' : ''}/>
                                        <label for="${params.id}-option-${index}">${option.label}</label>
                                    </div>`
                    })
                    html += /*html*/`
                                </div>
                            </div>
                        </div>`
                }
                break;
            case 'check':
                html += /*html*/`
                        <div class="items-container">`
                html += /*html*/`
                            <div class="item">
                                <input type="checkbox" id="${params.id}" class="width-checkbox height-checkbox ${params.inputClass ? params.inputClass : ''}" name="${params.name}" ${params.value ? 'checked' : ''} ${params.readonly ? 'readonly' : ''}>`
                if(params.label) html += /*html*/`
                                <label for="${params.id}">${params.label}${params.required ? ' *' : ''}</label>
                            </div>`
                html += /*html*/`
                        </div>`
                break;
        }
        if(params.multiple && !params.readonly) html += /*html*/`
                <div class="bottom">
                    ${this.btn({
                        id: 'btn-'+params.id+'-add',
                        content: '+ '+params.multiple.label,
                        class: 'btn padding-0-5 t-small medium uppercase color-bluegrey transition-t-color hover-color-rehausse',
                        onClick: function(el, event) {
                            event.preventDefault();
                            var field = el.closest('.field'),
                                items = field.querySelectorAll('.item'),
                                itemsContainer = field.querySelector('.items-container'),
                                item = items[0],
                                newItem = item.cloneNode(true),
                                newItemInputs = newItem.querySelectorAll('input, textarea'),
                                newItemLabel = newItem.querySelector('label'),
                                newItemImage = newItem.querySelector('.image-container img'),
                                newItemRemoveBtn = newItem.querySelector('.remove');
                            if(newItemLabel) {
                                newItemLabel.setAttribute('for', newItemLabel.getAttribute('for')+'-'+items.length);
                            }
                            newItemInputs.forEach(function(el) {
                                el.id += '-'+items.length;
                                el.name += '-'+items.length;
                                el.value = '';
                                el.innerText = '';
                            })
                            if(params.type == 'file') {
                                if((items.length > 1) && items[items.length - 1].classList.contains('empty')) items[items.length - 1].remove();
                                newItem.classList.add('empty', 'hidden');
                                newItemLabel.innerText = newItemLabel.getAttribute('data-original');
                                newItemImage.src = '';
                                that.fileItemListener(newItem, params.url);
                            }
                            if(newItemRemoveBtn) { 
                                newItemRemoveBtn.classList.remove('no-events');
                                newItemRemoveBtn.classList.remove('opacity-0');
                            }
                            that.removableItemListener(newItem);
                            itemsContainer.append(newItem);
                            // Auto browse
                            if(params.type == 'file') {
                                items = field.querySelectorAll('.item');
                                items[items.length - 1].querySelector('input[type="file"]').click();
                            }
                        }
                    })}
                </div>`;
        html += /*html*/`
                </div>`
        if(params.clear) html += /*html*/`
                <div class="clear"></div>`;

        // Comportements spécifiques
        // Color
        if(params.type && (params.type == 'color')) {
            this.postActions.push(function(next) {
                var input = that.target.querySelector('input#'+params.id+', select#'+params.id),
                    field = input.closest('.field'),
                    hexaValue = field.querySelector('.hexa-value');
                input.addEventListener('change', function(event) {
                    hexaValue.value = input.value;
                })
                hexaValue.addEventListener('keyup', function(event) {
                    input.value = hexaValue.value;
                })
                if(next) next();
            })
        }
        // File
        if(params.type && (params.type == 'file')) {
            this.postActions.push(function(next) {
                var field = that.target.querySelector('#field-'+params.id),
                    items = field.querySelectorAll('.item');
                items.forEach(function(el) {
                    that.fileItemListener(el, params.url);
                })
                if(next) next();
            })
        }

        // Removable
        if(params.multiple) {
            this.postActions.push(function(next) {
                var field = that.target.querySelector('#field-'+params.id),
                    items = field.querySelectorAll('.item');
                items.forEach(function(el) {
                    that.removableItemListener(el);
                })
                if(next) next();
            })
        }

        // Onchange
        if(params.onChange) this.postActions.push(function(next) {
            var input = document.querySelector('input#'+params.id+', select#'+params.id);
            if(input) input.addEventListener('change', function(event) {
                var field = event.target.closest('.field');
                params.onChange(field);
            })
            if(next) next();
        });

        return html;
    }
    removableItemListener (item, url) {
        var removeBtn = item.querySelector('.remove');
        if(removeBtn) removeBtn.addEventListener('click', function(event) {
            item.remove();
        })
    }
    fileItemListener (item, url) {
        item.querySelector('input[type="file"]').addEventListener('change', function(event) {
            var item = event.target.closest('.item'),
                inputLabel = item.querySelector('.input-container label'),
                image = item.querySelector('.image-container img'),
                hiddenInput = item.querySelector('input[type="hidden"]');
            inputLabel.innerHTML = 'Envoi en cours... 0%';
            XhrHelper.submitFile(this, url, function(value) {
                inputLabel.innerHTML = 'Envoi en cours... '+value+'%';
            }, function(data) {
                hiddenInput.value = data.url;
                image.src = data.url;
                item.classList.remove('empty', 'hidden');
                inputLabel.innerHTML = 'Envoi terminé';
            })
        })
    }
    // COMBO (AJAX SELECT)
    // -----------
    comboField (params) {
        var that = this,
            html,
            oldSchemaIndex,
            oldOnChangeIndex,
            arrValues = [],
            selectionHtml = '',
            selectionCount = 0,
            hiddenValue = false,
            item,
            newItemCallback = function(item) {
                var field = that.target.querySelector('#field-'+params.id),
                    selection = field.querySelector('.selection');
                if(field.classList.contains('multiple')) {
                    selection.innerHTML += that.comboFieldSelection(item[params.ajax.value], that.comboFieldLabel(params.ajax.schema, item), (params.required ? false : true));
                } else {
                    selection.innerHTML = that.comboFieldSelection(item[params.ajax.value], that.comboFieldLabel(params.ajax.schema, item), (params.required ? false : true));
                }
                field.classList.remove('open');
                that.updateComboValue(field);
                field.querySelectorAll('.selection .option').forEach(function(optionEl) {
                    that.comboFieldSelectionListener(optionEl);
                });
                Tools.getElementFromProperty(that.app.modalViews, 'id', 'new-'+params.new.type).close();
            };

        if(params.value) {
            if(params.multiple) {
                arrValues = [];
                params.value.forEach(function(subEl) {
                    arrValues.push(subEl[params.ajax.value]);
                    selectionHtml += that.comboFieldSelection(subEl[params.ajax.value], that.comboFieldLabel(params.ajax.schema, subEl), params.readonly ? false : true);
                    selectionCount += 1;
                })
                hiddenValue = JSON.stringify(arrValues).replace(/"/g, '&quot;');
            } else {
                item = (params.value instanceof Array) ? params.value[0] : params.value;
                if(item) {
                    selectionHtml = that.comboFieldSelection(item[params.ajax.value], that.comboFieldLabel(params.ajax.schema, item), ((params.required || params.readonly) ? false : true));
                    hiddenValue = item[params.ajax.value];
                    selectionCount += 1;
                }
            }
        }
        
        html = /*html*/`
            <div id="field-${params.id}" class="field spe-parent combo combo-${params.type ? params.type : 'std'} ${params.multiple ? 'multiple' : ''} ${params.class ? params.class : ''} ${params.readonly ? 'readonly' : ''}" 
                ${(params.ajax && params.ajax.value) ? 'data-ajax-value="'+params.ajax.value+'"' : ''}
            >
            ${params.label ? /*html*/`
                <label for="search-${params.id}"><div class="middle-holder"><div class="middle">${params.label}${params.required ? ' *' : ''}</div></div></label>`
            : ''}
                <div class="items-container">
                    <div class="item">
                        <div class="zone">
                            <div class="datalist">
                                <div class="search-zone">
                                    <input class="filter-string" type="text" autocomplete="off" placeholder="Rechercher..." id="search-${params.id}" ${params.readonly ? 'readonly' : ''}>
                                    <div class="abs opacity-60 top-50 top-margin--0-625 height-1-25em left-margin-0-5 svg-fit-height fill-color-mediumgrey">${this.app.files['svg/picto_search']}</div>
                                </div>
                                <div class="content"></div>
                            </div>
                            <div class="input-container ${params.new ? 'right-pad-3' : ''}">
                                ${(params.type && (params.type == 'check')) ? /*html*/`<div id="selection-${params.id}-count" class="selection-count">${this.comboFieldSelectionCountString(selectionCount)}</div>` : ''}
                                <div id="selection-${params.id}" class="selection">${selectionHtml}</div>
                                ${(params.new && !params.readonly) ? /*html*/`
                                    ${that.btn({
                                        id: 'btn-input-'+params.id+'-new',
                                        content: /*html*/`<span class="t-std">+</span>`,
                                        class: 'btn abs right top-50 right-margin-0-5 top-margin--0-75 center-holder width-1-5em height-1-5em rounded-0-25em border-1 color-mediumgrey border-color-bluegrey hover-color-rehausse transition-t-color',
                                        onClick: function(btn) {
                                            that.app.openModal('view', {
                                                id: 'new-'+params.new.type,
                                                view:
                                                    (params.new.type == 'user') ? that.app.createNewUserView({ role : params.new.role, company: params.new.company ? params.new.company() : false }, newItemCallback) : 
                                                    (params.new.type == 'company') ? that.app.createNewCompanyView(newItemCallback) : 
                                                    false
                                            });
                                        }
                                    })}` : ''}
                                ${((params.type && ((params.type == 'check') || (params.type == 'select'))) && !params.readonly) ? this.btn({
                                    id: 'btn-dropdown-field-'+params.id,
                                    class: 'btn z-index-2 abs right block height-100 width-3em flexbox center opacity-80 z-index-4 no-events open-spe-parent-events fill-color-mediumgrey hover-direct-parent-fill-color-rehausse transition-fill-color',
                                    content: /*html*/`<div class="height-0-5em svg-fit-height active-spe-parent-rotated-180 open-spe-parent-rotated-180 transition-transform">${this.app.files['svg/picto_dropdown']}</div>`,
                                    onClick: function(el, event) {
                                        event.preventDefault();
                                        var field = that.target.querySelector('#field-'+params.id);
                                        if(field.classList.contains('open')) field.classList.remove('open');
                                        return false;
                                    }
                                }) : ''}
                            </div>
                        </div>
                        <input type="hidden" id="${params.id}" name="${params.name}" ${(hiddenValue !== false) ? /*html*/`value="${hiddenValue}"` : ''} ${params.required ? 'required' : ''}>
                    </div>
                </div>
            </div>`

        if(params.ajax && params.ajax.schema) {
            if(!this.comboFieldSchemas) this.comboFieldSchemas = [];
            oldSchemaIndex = Tools.getIndexFromProperty(this.comboFieldSchemas, 'id', params.id);
            if(oldSchemaIndex !== false) this.comboFieldSchemas.splice(oldSchemaIndex, 1);
            this.comboFieldSchemas.push({
                id: params.id,
                action: params.ajax.schema
            })
        }

        if(params.onChange) {
            if(!this.comboFieldOnChanges) this.comboFieldOnChanges = [];
            oldOnChangeIndex = Tools.getIndexFromProperty(this.comboFieldOnChanges, 'id', params.id);
            if(oldOnChangeIndex !== false) this.comboFieldOnChanges.splice(oldOnChangeIndex, 1);
            this.comboFieldOnChanges.push({
                id: params.id,
                action: params.onChange
            })
        }

        if(!params.readonly) {
            this.postActions.push(function(next) {
                var field = that.target.querySelector('#field-'+params.id),
                    input = field.querySelector('#search-'+params.id),
                    hidden = field.querySelector('input[type="hidden"]'),
                    container = field.querySelector('.input-container'),
                    selection = field.querySelector('.selection'),
                    datalistContent = field.querySelector('.datalist .content'),
                    option,
                    html,
                    value,
                    fieldValue,
                    changed,
                    selected;
                switch(params.type) {
                    case 'check':
                        field.addEventListener('mouseleave', function() { 
                            field.classList.remove('open');
                        });
                        var action = function(event) { 
                            input.focus();
                            params.ajax.data(input.value, function(data) {
                                if(data.length) {
                                    html = '';
                                    value = that.getFieldValue(params.id);
                                    data.forEach(function(result) {
                                        selected = field.classList.contains('multiple') ? (value.indexOf(String(result[params.ajax.value])) > -1) : (value == String(result[params.ajax.value]));
                                        html += that.comboFieldOption(params.type, result[params.ajax.value], that.comboFieldLabel(params.ajax.schema, result), selected);
                                    })
                                    datalistContent.innerHTML = html;
                                    datalistContent.querySelectorAll('input[type="checkbox"]').forEach(function(el) {
                                        el.addEventListener('change', function() {
                                            input.focus();
                                            option = this.closest('.option');
                                            value = option.getAttribute('data-value');
                                            if(this.checked) {
                                                if(field.classList.contains('multiple')) {
                                                    selection.innerHTML += that.comboFieldSelection(value, this.innerHTML, true);
                                                } else {
                                                    selection.innerHTML = that.comboFieldSelection(value, this.innerHTML, true);
                                                }
                                            } else {
                                                selection.querySelector('.option[data-value="'+value+'"]').remove();
                                            }
                                            that.updateComboSelectionCount(field);
                                            that.updateComboValue(field);
                                        });
                                    })
                                } else {
                                    datalistContent.innerHTML = '';
                                }
                                field.classList.add('open');
                            });
                        }
                        input.addEventListener('keyup', action);
                        container.addEventListener('click', action);
                        break;
                    default:
                        field.addEventListener('mouseleave', function() { 
                            field.classList.remove('open');
                        });
                        var action = function(event) {
                            input.focus();
                            params.ajax.data(input.value, function(data) {
                                if(data && data.length) {
                                    html = '';
                                    data.forEach(function(result) {
                                        html += that.comboFieldOption(params.type, result[params.ajax.value], that.comboFieldLabel(params.ajax.schema, result));
                                    })
                                    datalistContent.innerHTML = html;
                                    datalistContent.querySelectorAll('.option').forEach(function(el) {
                                        el.addEventListener('click', function() {
                                            value = this.getAttribute('data-value');
                                            fieldValue = that.getComboValue(field);
                                            changed = false;
                                            if(field.classList.contains('multiple')) {
                                                if(!(fieldValue && (fieldValue.indexOf(value) > -1))) {
                                                    selection.innerHTML += that.comboFieldSelection(value, this.innerHTML, true);
                                                    changed = true;
                                                }
                                            } else {
                                                selection.innerHTML = that.comboFieldSelection(value, this.innerHTML, (hidden.required ? false : true));
                                                changed = true;
                                                field.classList.remove('open');
                                            }
                                            if(changed) {
                                                input.value = '';
                                                that.updateComboValue(field);
                                                field.querySelectorAll('.selection .option').forEach(function(optionEl) {
                                                    that.comboFieldSelectionListener(optionEl);
                                                });
                                            }
                                        });
                                    })
                                } else {
                                    datalistContent.innerHTML = '';
                                }
                                field.classList.add('open');
                            })
                            event.preventDefault();
                            return false;
                        }
                        input.addEventListener('keyup', action);
                        container.addEventListener('click', action);
                        selection.querySelectorAll('.option').forEach(function(optionEl) {
                            that.comboFieldSelectionListener(optionEl);
                        })
                        break;
                }
                that.comboFieldValidation(field);
                if(next) next();
            });
        }
    
        return html;
    }
    comboFieldOption (type, value, label, selected) {
        return /*html*/`
            <${(type == 'check') ? 'label' : 'div'} class="option clear-after" data-value="${value}"><!--
            -->${(type == 'check') ? /*html*/`
                    <input type="checkbox" id="input-check-${value}" class="float width-checkbox height-checkbox padding-0 margin-0 border-color-grey rounded-0-25em cursor-pointer" ${selected ? 'checked': ''}>
                    <div class="left-pad-1-5">${label}</div>`
                : /*html*/`${label}`}<!--
            --></${(type == 'check') ? 'label' : 'div'}>
            <div class="clear"></div>`
    }
    comboFieldLabel (schema, values) {
        return schema(values);
    }
    comboFieldSelection (value, content, removable) {
        return /*html*/`<div class="option" data-value="${value}"><span class="label">${content}</span>${removable ? /*html*/`&nbsp<span class="remove">&#10006;</span>` : ''}</div>`;
    }
    comboFieldSelectionListener (optionEl) {
        var that = this,
            field = optionEl.closest('.field'),
            remover = optionEl.querySelector('.remove');
        if(remover) remover.addEventListener('click', function(event) {
            event.preventDefault();
            event.stopPropagation();
            this.closest('.option').remove();
            that.updateComboValue(field);
            return false;
        })
    }
    updateComboSelectionCount (field) {
        var selectionCount = field.querySelector('.selection-count'),
            selectedOptions = field.querySelectorAll('.selection .option');
        selectionCount.innerHTML = this.comboFieldSelectionCountString(selectedOptions.length);
    }
    comboFieldSelectionCountString (count) {
        return count ? (count+' sélectionné(s)') : 'Tous';
    }
    updateComboValue (field) {
        var options = field.querySelectorAll('.selection .option'),
            hidden = field.querySelector('input[type="hidden"]'),
            onChangeIndex = this.comboFieldOnChanges ? Tools.getIndexFromProperty(this.comboFieldOnChanges, 'id', hidden.id) : false,
            onChange = (onChangeIndex !== false) ? this.comboFieldOnChanges[onChangeIndex].action : false,
            tempValues;
        if(field.classList.contains('multiple')) {
            tempValues = [];
            options.forEach(function(optionEl) {
                tempValues.push(optionEl.getAttribute('data-value'));
            })
            if(tempValues.length) {
                hidden.value = JSON.stringify(tempValues);
            } else {
                hidden.removeAttribute('value');
            }
        } else {
            if(options[0]) {
                hidden.value = options[0].getAttribute('data-value');
            } else {
                hidden.removeAttribute('value');
            }
        }
        this.comboFieldValidation(field);
        if(onChange) onChange(field);
    }
    comboFieldValidation (field) {
        var input = field.querySelector('input[type="text"]'),
            hidden = field.querySelector('input[type="hidden"]'),
            value = this.getComboValue(field),
            error = (hidden.required && !value);
        if(error) {
            input.setCustomValidity(this.defaultRequiredMessage);
            field.classList.add('invalid');
        } else {
            input.setCustomValidity('');
            field.classList.remove('invalid');
        }
    }
    // Editeur riche
    editorField (params) {
        var that = this,
            html = /*html*/`
                <div id="field-${params.id}" class="field rich ${params.class ? params.class : ''} ${params.readonly ? 'readonly' : ''}">`
        if(params.label) html += /*html*/`
                    <label>${params.label}${params.required ? ' *' : ''}</label>`
        html += /*html*/`
                    <div class="item">
                        <div class="input-container">
                            <div id="holder-${params.id}" class="min-height-3em"></div>
                        </div>
                    </div>
                </div>`;
    
        this.postActions.push(function(next) {
            var oldRichEditorIndex,
                editor = new mediumEditor('#holder-'+params.id, {
                    placeholder: params.placeholder ? {
                        text: params.placeholder,
                        hideOnClick: true
                    } : false,
                    disableEditing: params.readonly
                });

            if(!that.richEditors) that.richEditors = [];
            oldRichEditorIndex = Tools.getIndexFromProperty(that.richEditors, 'id', params.id);
            if(oldRichEditorIndex !== false) that.richEditors.splice(oldRichEditorIndex, 1);
            that.richEditors.push({
                id: params.id,
                instance: editor
            })
            if(params.value) editor.setContent(params.value);
            if(next) next();
        });

        return html;
    }
    // Valeur de champ spécial
    getComboValue (field) {
        var id = field.id.replace('field-', ''),
            hidden = field.querySelector('#'+id),
            out = hidden ? hidden.value : false;
        if(hidden.value) {
            if(field.classList.contains('multiple')) {
                out = JSON.parse(hidden.value);
            }
        }
        return out;
    }
    getRadioValue (field) {
        return field.querySelector('input[type="radio"]:checked').value;
    }
    getRichValue (field) {
        var id = field.id.replace('field-', ''),
            editor = Tools.getElementFromProperty(this.richEditors, 'id', id);
        return editor ? editor.instance.getContent() : false;
    }
    // Valeur de champ d'id donné
    getFieldValue (id) {
        var field = this.target.querySelector('#field-'+id),
            value = false;
        if(field) {
            if(field.classList.contains('combo')) {
                value = this.getComboValue(field);
            } else {
                if(field.classList.contains('radio')) {
                    value = this.getRadioValue(field);
                } else {
                    if(field.classList.contains('rich')) {
                        value = this.getRichValue(field);
                    } else {
                        if(field.classList.contains('multiple')) {
                            value = [];
                            field.querySelectorAll(field.classList.contains('file') ? 'input[type="hidden"]' : 'input, textarea').forEach(function(el) {
                                if(el.value) value.push(el.value);
                            })
                        } else {
                            value = this.target.querySelector('#'+id).value;
                        }
                    }
                }
            }
        }
        return value;
    }

    // Barre de progression affectations
    static affectationsProgression (affectations) {
        var progression = {};
        if(affectations) {
            affectations.forEach(function(el, index) {
                progression.total = progression.total ? (progression.total + 1) : 1;
                if(el.schedule && !el.published) progression.red = progression.red ? (progression.red + 1) : 1;
                if(el.published && !el.finished) progression.yellow = progression.yellow ? (progression.yellow + 1) : 1;
                if(el.finished) progression.green = progression.green ? (progression.green + 1) : 1;
                progression.grey = 
                    progression.total - 
                    (progression.red ? progression.red : 0) - 
                    (progression.yellow ? progression.yellow : 0) - 
                    (progression.green ? progression.green : 0);
                progression.html = /*html*/`
                    <div class="right-pad-2 top-pad-0-5 top-pad-1-mobile">
                        <div class="flexbox width-10em height-0-5em">
                            ${progression.green ? /*html*/`<div class="bg-color-progress-green" style="flex:${progression.green};">
                                <div class="abs bot-100 width-100 center-holder"><div class="t-smallest medium color-progress-green">${progression.green}</div></div>
                            </div>` : ''}
                            ${progression.yellow ? /*html*/`<div class="bg-color-progress-yellow" style="flex:${progression.yellow};">
                                <div class="abs bot-100 width-100 center-holder"><div class="t-smallest medium color-progress-yellow">${progression.yellow}</div></div>
                            </div>` : ''}
                            ${progression.red ? /*html*/`<div class="bg-color-progress-red" style="flex:${progression.red};">
                                <div class="abs bot-100 width-100 center-holder"><div class="t-smallest medium color-progress-red">${progression.red}</div></div>
                            </div>` : ''}
                            ${progression.grey ? /*html*/`<div class="bg-color-progress-grey" style="flex:${progression.grey};"></div>` : ''}
                            <div class="abs top-50 left-100 left-pad-0-5 height-2em top-margin--1 flexbox center">
                                <div class="inline-block t-std light color-bluegrey">/<span class="left-pad-0-1">${progression.total}</span></div>
                            </div>
                        </div>
                    </div>`;
            })
        }
        return progression;
    }

    // Etiquette de tag/terme/type de contenu
    static tagElement (params) {
        return /*html*/`<div class="inline-block v-align-middle ${(params.borderSize && (params.borderSize > 1)) ? 'padding-0-5 left-pad-0-75 right-pad-0-75' : 'padding-0-25 left-pad-0-5 right-pad-0-5'}  
            border-${params.borderSize ? params.borderSize : 1} 
            rounded-${params.rounded ? '1em' : '0-5em'} border-color-bluegrey"
            style="
                ${params.borderColor ? 'border-color:'+params.borderColor+' !important;' : ''} 
                ${params.textColor ? 'color:'+params.textColor+' !important;' : ''} 
                ${params.bgColor ? 'background-color:'+params.bgColor+' !important;' : ''} 
            ">
                ${params.icon ? /*html*/`
                <span class="inline-block height-0-75em svg-fit-height ${params.iconClass ? params.iconClass : 'fill-color-darkblue'} right-pad-0-15">${params.icon}</span>` : ''}
                <span class="v-align-middle ${(params.borderSize && (params.borderSize > 1)) ? '' : 't-smaller'}">${params.content}</span>
            </div>`;
    }

    // Note
    notes (notes, picto) {
        var that = this,
            html = '';
        notes.forEach(function(note, index) {
            html += /*html*/`
            <div class="note v-align-middle ${index ? 'top-margin-0-75' : ''}">
                <div class="t-smaller ${!note.created ? 'left-pad-1-75' : ''}">
                    <div class="${!note.created ? 'abs top-margin--0-2' : 'flexbox center'}">
                        <div class="width-1-25em height-1-25em svg-fit-height">${picto}</div>
                        <div class="flex left-pad-0-25 bold">${note.created ? /*html*/`${that.niceDateTime(note.created)}` : ''}</div>
                    </div>
                    <div class="${note.created ? 'top-margin-0-25' : ''}">
                        ${note.content ? note.content : note}
                    </div>
                </div>
            </div>`
        })
        return html;
    }

    // Transformation dates
    utcDateToLocalMoment (string) {
        return this.app.moment.utc(string).local();
    }
    utcDateToLocal (string) {
        return this.utcDateToLocalMoment(string).format('YYYY-MM-DD');
    }
    utcHourToLocal (string) {
        return this.utcDateToLocalMoment(string).format('HH:mm');
    }
    utcHourSimpleToLocal (string) {
        return this.utcDateToLocalMoment(string).hours();
    }
    utcDateTimeToLocal (string) {
        return this.utcDateToLocalMoment(string).format('YYYY-MM-DD HH:mm');
    }
    niceDate (string) {
        return this.utcDateToLocalMoment(string).format('DD/MM/YYYY');
    }
    niceDateTime (string) {
        return this.utcDateToLocalMoment(string).format('DD/MM/YYYY [à] HH[h]mm');
    }
    dbDate (moment) {
        return moment.clone().utc().local().format('YYYY-MM-DD');
    }

    // Rendu asynchrone
    // Actions précédant le rendu
    executePreActions (callback, curIndex) {
        var that = this,
            i = curIndex ? curIndex : 0;
        if(i < this.preActions.length) {
            this.preActions[i](function() {
                that.executePreActions(callback, i + 1);
            })
        } else {
            this.preActions = [];
            if(callback) callback();
        }
    }
    // Actions suivant le rendu
    executePostActions (callback, curIndex) {
        var that = this,
            i = curIndex ? curIndex : 0;
        if(i < this.postActions.length) {
            this.postActions[i](function() {
                that.executePostActions(callback, i + 1);
            })
        } else {
            this.postActions = [];
            if(callback) callback();
        }
    }
}

export { View };