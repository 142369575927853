'use strict';

import { View } from '../view.js';
import { System } from '../models/system.js';

class LoginView extends View {
    constructor(app) {
        super(app);
        this.name = 'Login';
    }

    get html () {
        var that = this;
        return /*html*/`
            <div class="height-100vh">
                <div class="middle-holder">
                    <div class="middle">
                        <div class="center-holder">
                            <div class="svg-fit-height height-8em height-4em-mobile inline-block">
                                ${this.app.files['svg/logo_full_dark']}
                            </div>
                            <div class="top-margin-5">
                                <div class="width-login-col width-100-mobile left-pad-2 right-pad-2 border-box centered">
                                    <form class="form left-holder width-100" id="form-system-login" method="post" enctype="multipart/form-data" id="login" action="/login" onsubmit="event.preventDefault(); return false;">
                                        <div class="message center-holder border-1 padding-1 rounded-0-5em opacity-0 active-opacity-100 transition-opacity active-bot-margin-2"></div>
                                        <div class="fields">
                                            ${this.formField({ type: 'email', id: 'input-email', name: 'email', label: 'Email', placeholder: 'xxxxxx@xxxxxx.com', autocomplete: 'email', class: 'width-100', required: true })}
                                            ${this.formField({ type: 'password', id: 'input-pwd', name: 'pwd', label: 'Mot de passe', placeholder: '***********', autocomplete: 'current-password', class: 'width-100 top-margin-1-5', required: true })}
                                            ${this.formField({ type: 'check', id: 'input-remember', name: 'remember', label: 'Se souvenir de moi', class: 'top-margin-2' })}
                                            ${this.btn({ type: 'submit', id: 'form-submit', content: 'Se connecter', class: 'btn primary rehausse width-100 top-margin-2',
                                                onClick: function(btn, event) {
                                                    event.preventDefault();
                                                    that.sendForm(btn);
                                                    return false;
                                                }
                                            })}
                                            <div class="top-margin-1-5 center-holder">
                                                ${this.btn({
                                                    id: 'btn-recover',
                                                    content: 'Mot de passe oublié ?',
                                                    class: 'btn t-small color-bluegrey',
                                                    onClick: function(btn, event) {
                                                        event.preventDefault();
                                                        that.app.goToPage('/recover');
                                                        return false;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
    }

    sendForm (btn) {
        var form = document.getElementById('form-system-login'),
            message = form.querySelector('.message'),
            data = {};
        form.reportValidity();
        if(form.checkValidity()) {
            btn.disabled = true; btn.classList.add('pending');
            data = {
                email: this.getFieldValue('input-email'),
                pwd: this.getFieldValue('input-pwd'),
                remember: this.getFieldValue('input-remember')
            }
            System.login(data, function(result) {
                document.location.href = '/';
            }, function(request, status) {
                btn.disabled = false; btn.classList.remove('pending');
                message.innerHTML = /*html*/`
                    <div class="t-std bold">Erreur lors de la connexion.</div> 
                    <div class="top-margin-1">
                        <div class="t-small">Veuillez réessayer après avoir vérifié vos identifiants.</div>
                    </div>`;
                message.classList.add('active','color-red','border-color-red');
            })
        }
    }
}

export { LoginView };