'use strict';

import { XhrHelper } from '../xhr.js';

class System {

    static login (params, onSuccess, onError) {
        XhrHelper.request({
            method: 'POST',
            json: true,
            url: '/login',
            data: params,
            success: function (data) {
                if(onSuccess) onSuccess(data); 
            },
            error: function(request, status) {
                if(onError) onError(request, status); 
            }
        });
    }

    static recover (params, onSuccess, onError) {
        XhrHelper.request({
            method: 'POST',
            json: true,
            url: '/recover',
            data: params,
            success: function (data) {
                if(onSuccess) onSuccess(data); 
            },
            error: function(request, status) {
                if(onError) onError(request, status); 
            }
        });
    }

    static password (params, onSuccess, onError) {
        XhrHelper.request({
            method: 'POST',
            json: true,
            url: '/password',
            data: params,
            success: function (data) {
                if(onSuccess) onSuccess(data); 
            },
            error: function(request, status) {
                if(onError) onError(request, status); 
            }
        });
    }

}

export { System };