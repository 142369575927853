'use strict';

import { View } from '../view.js';
import { System } from '../models/system.js';

class RecoverView extends View {
    constructor(app) {
        super(app)
        this.name = 'Recover';
    }

    get html () {
        var that = this;
        return /*html*/`
            <div class="height-100vh">
                <div class="middle-holder">
                    <div class="middle">
                        <div class="center-holder">
                            <div class="svg-fit-height height-8em height-4em-mobile inline-block">
                                ${this.app.files['svg/logo_full_dark']}
                            </div>
                            <div class="top-margin-5">
                                <div class="width-login-col width-100-mobile left-pad-2 right-pad-2 border-box centered">
                                    <div class="center-holder color-darkblue">
                                        <div class="t-big medium">Demande de réinitialisation <br class="hide-mobile">de mot de passe</div>
                                    </div>
                                    <form class="form left-holder width-100 top-margin-1" id="form-system-recover" method="post" enctype="multipart/form-data" id="recover" action="/recover" onsubmit="event.preventDefault(); return false;">
                                        <div class="message center-holder t-small border-1 border-color-grey padding-1 rounded-0-5em opacity-0 active-opacity-100 transition-opacity active-bot-margin-2 active">
                                            <div class="t-small">Vous recevrez un email vous permettant <br class="hide-mobile">de créer un nouveau mot de passe.</div>
                                        </div>
                                        <div class="fields">
                                            ${this.formField({ type: 'email', id: 'input-email', name: 'email', label: 'Email', placeholder: 'xxxxxx@xxxxxx.com', autocomplete: 'email', class: 'width-100', required: true })}
                                            ${this.btn({ type: 'submit', id: 'form-submit', content: 'Envoyer la demande', class: 'btn primary rehausse width-100 top-margin-2', 
                                                onClick: function(btn, event) {
                                                    event.preventDefault();
                                                    that.sendForm(btn);
                                                    return false;
                                                }
                                            })}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
    }
    
    sendForm (btn) {
        var form = document.getElementById('form-system-recover'),
            message = form.querySelector('.message'),
            fields = form.querySelector('.fields'),
            data = {};
        form.reportValidity();
        if(form.checkValidity()) {
            btn.disabled = true; btn.classList.add('pending');
            data = {
                email: this.getFieldValue('input-email')
            }
            System.recover(data, function(result) {
                btn.disabled = false; btn.classList.remove('pending');
                message.innerHTML = /*html*/`
                    <div class="t-std bold">La demande de réinitialisation du mot de passe <br class="hide-mobile">a bien été reçue.</div> 
                    <div class="top-margin-1">
                        <div class="t-small">Vous allez recevoir un email vous permettant <br class="hide-mobile">de créer un nouveau mot de passe.</div>
                    </div>`;
                message.classList.add('color-rehausse', 'border-color-rehausse');
                fields.remove();
            }, function(request, status) {
                btn.disabled = false; btn.classList.remove('pending');
                message.innerHTML = /*html*/`
                    <div class="t-std bold">Erreur lors de la demande de <br class="hide-mobile">réinitialisation du mot de passe.</div> 
                    <div class="top-margin-1">
                        <div class="t-small">Veuillez réessayer après avoir vérifié votre adresse email.</div>
                    </div>`;
                message.classList.add('color-red', 'border-color-red');
            })
        }
    }
}

export { RecoverView };